import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import {
    Container,
    Content,
} from "./styles";

export default function Answered() {
    const history = useHistory();
    const [load, setLoad] = useState(true);
    const [dataAnswered, setDataAnswered] = useState<any>();
    
    useEffect(() => {
        setLoad(true);
        api.get('/pesquisa').then((res) => {
            setDataAnswered(res.data.result[0].respondida)
        })
        setLoad(false); 
    }, [])

    useEffect(() => {
        if (dataAnswered === true) {
            setTimeout(() => {
                window.localStorage.removeItem('@User:user');
                window.localStorage.removeItem('@Token:token');
                window.location.reload();
            }, 5000)
        }
        else if (dataAnswered === false) {
            history.push('pesquisa/1')
        }
    }, [dataAnswered])    
     
    return (
        <Container>
            <Content>
                {
                    dataAnswered 
                    ?   <h1>
                            A pesquisa já foi respondida. 
                            <br />
                            Até uma próxima. 😎
                        </h1>

                    :   ''
                }
            </Content>
        </Container>
    );
}